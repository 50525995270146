<template>
  <div>
    <div v-if="records_data">
      <div
        class="record position-relative"
        v-for="(record, index) in records_data"
        :key="index"
      >
        <h3 @click="showDetail(record)" class="text-primary" v-if="record.urn">
          {{ record.corporate_name }} - {{ record.country }}
        </h3>
        <h3 v-else class="disabledLink">
          {{ record.corporate_name }} - {{ record.country }}
        </h3>
        <div v-if="record.timestamp">
          <small>
            {{ formatted_time(record.timestamp) }}
          </small>
        </div>
        <base-button
          @click="showDetail(record)"
          size="sm"
          type="text"
          class="btn-round ntt"
          v-if="record.urn"
          >Show Detail <i class="fa fa-info-circle"></i
        ></base-button>
        <div v-else class="badge badge-outdated">outdated</div>
      </div>
      <div class="mt-3">
        <base-alert v-if="not_found" type="info"
          >Saved records not found</base-alert
        >
        <base-pagination
          class="justify-content-center"
          :page-count="pages_count"
          v-model="page"
          iconLeft="ni ni-bold-left"
          iconRight="ni ni-bold-right"
          type="info"
        ></base-pagination>
      </div>
    </div>
    <div v-else-if="!records" class="text-center">
      <spinner size="lg" />
    </div>
    <div v-else>
      <base-alert type="danger"
        >Error, Could not get records.
        <base-button type="primary" outline size="sm" @click="getSavedRecords()"
          >Reload <i class="fa fa-history"></i></base-button
      ></base-alert>
    </div>
  </div>
</template>

<script>
import Spinner from "@/components/Spinner";
export default {
  components: {
    Spinner
  },
  data: () => ({
    records: {
      page: 1
    },
    error: null,
    path: "/search/saved-records/?page_size=20&page="
  }),
  computed: {
    page: {
      get() {
        return this.records.page;
      },
      set(val) {
        this.records.page = val;
      }
    },
    pages_count() {
      if (this.records) {
        return Math.ceil(this.records.count / 20);
      } else {
        return 1;
      }
    },
    records_data() {
      if (this.records) {
        return this.records.results;
      } else {
        return null;
      }
    },
    not_found() {
      if (this.records) {
        if (this.records.results.length == 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  },
  mounted() {
    let stored_cache = this.$store.getters.records_cache;
    this.getSavedRecords();
    if (stored_cache) this.loadResult(stored_cache);
    // else
  },
  methods: {
    loadResult(res) {
      this.records = res;
      this.error = true;
    },
    getSavedRecords() {
      this.error = null;
      this.$axios
        .get(`${this.path}${this.page}`)
        .then(res => {
          res.data.page = this.page;
          this.loadResult(res.data);
          this.$store.commit("set_records_cache", res.data);
        })
        .catch(err => {
          if (err.response) {
            this.error = err.response.status;
          }
        });
    },
    showDetail(record) {
      this.$router.push(`/search/detail/${record.urn}-${record.country_code}`);
    }
  },
  watch: {
    page: function() {
      this.getSavedRecords();
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }
};
</script>

<style scoped>
.record {
  border: 0.0625rem solid #e9ecef;
  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;
}
.record:hover {
  background-color: #e9ecef;
}
h3 {
  font-size: 22px;
  line-height: 1.3;
}
h3:hover,
.btn-result:hover,
.record-link:hover {
  text-decoration: underline;
  cursor: pointer;
}
</style>
