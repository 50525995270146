<template>
  <div>
    <div v-if="records_data">
      <div
        class="record"
        v-for="(record, index) in records.results"
        :key="index"
      >
        <h3 @click="showDetail(record)" class="text-primary">
          <i class="fa fa-clock-o"></i> {{ record.terms }}
          <span>{{getCountryNames(record.country_codes)}}</span>
        </h3>
        <div>
          <small>
            {{ formatted_time(record.timestamp) }} - {{ record.browser }}
          </small>
        </div>
        <base-button
          @click="showDetail(record)"
          size="sm"
          type="text"
          class="btn-round ntt"
          >Search <i class="fa fa-search"></i
        ></base-button>
      </div>
      <div class="mt-3">
        <base-alert v-if="not_found" type="info"
          >Search history not found</base-alert
        >
        <base-pagination
          class="justify-content-center"
          :page-count="pages_count"
          v-model="page"
          iconLeft="ni ni-bold-left"
          iconRight="ni ni-bold-right"
          type="info"
        ></base-pagination>
      </div>
    </div>
    <div v-else-if="loading" class="text-center">
      <spinner size="lg" />
    </div>
    <div v-else>
      <base-alert type="danger"
        >Error, Could not get records.
        <base-button type="primary" outline size="sm" @click="getSavedRecords()"
          >Reload <i class="fa fa-history"></i></base-button
      ></base-alert>
    </div>
  </div>
</template>

<script>
import Spinner from "@/components/Spinner";
import Moment from "moment";
export default {
  components: {
    Spinner
  },
  data: () => ({
    records: {
      page: 1
    },
    error: null,
    path: "/search/search-terms/?page_size=20&page=",
    loading: true,
    country_list: []
  }),
  computed: {
    page: {
      get() {
        return this.records.page;
      },
      set(val) {
        this.records.page = val;
      }
    },
    pages_count() {
      if (this.records) {
        return Math.ceil(this.records.count / 20);
      } else {
        return 1;
      }
    },
    not_found() {
      if (this.error) {
        if (this.error.status == 404) {
          return true;
        }
      }
      if (this.records) {
        if (this.records.results.length == 0) {
          return true;
        }
      }
      return false;
    },
    records_data() {
      if (this.records) {
        return this.records.results;
      } else {
        return null;
      }
    }
  },
  mounted() {
    let stored_cache = this.$store.getters.search_history_cache;
    this.getSearchTerms();
    this.getCountries();
    if (stored_cache) this.loadResult(stored_cache);
  },
  methods: {
    getCountries() {
      this.$axios.get("search/country-list/").then(res => {
        this.country_list = res.data;
      });
    },
    getCountryNames(codes) {
      let countries = "";
      if (codes && this.country_list.length > 0) {
        this.country_list.forEach(item => {
          if (codes.search(item.record_number) > -1) {
            countries += item.country_name + ", ";
          }
        });
      }
      if (countries.length > 0) {
        countries = countries.slice(0, countries.length - 2);
        countries = "- " + countries;
      }
      return countries;
    },
    formatted_time(d) {
      return Moment(d).format("lll");
    },
    from_now(d) {
      return Moment(d).fromNow();
    },
    loadResult(res) {
      this.records = res;
      this.error = true;
      this.loading = false;
    },
    getSearchTerms() {
      this.error = null;
      this.$axios
        .get(`${this.path}${this.page}`)
        .then(res => {
          res.data.page = this.page;
          this.loadResult(res.data);
          this.$store.commit("set_search_history_cache", res.data);
        })
        .catch(err => {
          this.loading = false;
          if (err.response) {
            this.error = err.response.status;
          }
        });
    },
    showDetail(record) {
      if (record.country_codes) {
        this.$router.push(
          `/search/?q=${record.terms}&c=${record.country_codes}`
        );
      } else {
        this.$router.push(`/search/?q=${record.terms}`);
      }
    }
  },
  watch: {
    page: function() {
      this.getSearchTerms();
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }
};
</script>

<style scoped>
.record {
  border: 0.0625rem solid #e9ecef;
  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;
}
.record:hover {
  background-color: #e9ecef;
}
h3 {
  font-size: 22px;
  line-height: 1.3;
}
h3:hover,
.btn-result:hover {
  text-decoration: underline;
  cursor: pointer;
}
</style>
