<template>
  <div class="container">
    <div class="mt-8">
      <div>
        <tabs pills fill>
          <tab-pane>
            <template slot="label"> Search History </template>
            <div>
              <searched-terms />
            </div>
          </tab-pane>
          <tab-pane>
            <template slot="label"> Realtime Search History </template>
            <div>
              <realtime-history />
            </div>
          </tab-pane>
          <tab-pane>
            <template slot="label"> Saved Records </template>
            <div>
              <saved-records />
            </div>
          </tab-pane>
        </tabs>
      </div>
    </div>
  </div>
</template>

<script>
import RealtimeHistory from "@/components/RealtimeHistory.vue";
import SearchedTerms from "@/components/SearchedTerms.vue";
import SavedRecords from "@/components/SavedRecords.vue";
import Tabs from "@/components/Tabs/Tabs.vue";
import TabPane from "@/components/Tabs/TabPane.vue";
export default {
  components: {
    Tabs,
    TabPane,
    RealtimeHistory,
    SearchedTerms,
    SavedRecords
  },
  metaInfo: {
    title: "SherlockIT"
  }
};
</script>
