<template>
  <div>
    <div v-if="realtime_history">
      <div
        class="history position-relative"
        v-for="(h, index) in records_data"
        :key="index"
      >
        <h3
          v-if="h.request_type == 'SINGLERECORD' && !h.urn_list.length"
          class="disabledLink"
        >
          {{ h.company_name.toString() }} - {{ h.country }}
        </h3>
        <h3
          v-else-if="h.request_type == 'SINGLERECORD'"
          @click="showHistoryDetail(h)"
          class="text-primary"
        >
          {{ h.company_name.toString() }} - {{ h.country }}
        </h3>
        <div v-else-if="h.request_type == 'DIRECTSEARCH'">
          <h3
            class="text-primary"
            @click="changeState(index)"
            v-if="h.urn_list.length"
          >
            {{ h.search_terms.toString() }} - {{ h.country }}
            <i
              v-if="h.urn_list.length && h.collapsed"
              class="fa fa-chevron-circle-up"
            ></i>
            <i
              v-if="h.urn_list.length && !h.collapsed"
              class="fa fa-chevron-circle-down"
            ></i>
          </h3>
          <h3 v-else class="disabledLink">
            {{ h.search_terms.toString() }} - {{ h.country }}
          </h3>
          <div v-show="h.collapsed" class="subSection">
            <div
              v-for="(name, index) in h.company_name"
              :key="`record${index}`"
            >
              <h4
                class="text-primary"
                @click="showSearchDetail(h.urn_list[index], h.country_code)"
              >
                {{ name }}
              </h4>
            </div>
          </div>
        </div>
        <div>
          <small
            >{{ formatted_time(h.request_time) }} | Request ID:
            {{ h.urid }}</small
          >
        </div>
        <base-button
          @click="showHistoryDetail(h)"
          size="sm"
          type="text"
          class="btn-round ntt"
          v-if="h.request_type == 'SINGLERECORD' && h.urn_list.length"
          >Show Detail <i class="fa fa-info-circle"></i
        ></base-button>
        <div :class="getClass(h.response_status)" class="ml-auto mt-1 badge">
          {{ h.response_status }}
        </div>
      </div>
    </div>
    <div v-else-if="realtime_history_error">
      <base-alert type="danger"
        >Error! Could not get realtime search history
        <base-button size="sm" @click="getRealtimeHistory()"
          >Reload <i class="fa fa-history"></i
        ></base-button>
      </base-alert>
    </div>
    <div v-else-if="!realtime_history" class="text-center">
      <spinner type="grow" />
    </div>
    <div class="mt-3">
      <base-alert v-if="not_found" type="info"
        >Saved records not found</base-alert
      >
      <base-pagination
        class="justify-content-center"
        :page-count="pages_count"
        v-model="page"
        iconLeft="ni ni-bold-left"
        iconRight="ni ni-bold-right"
        type="info"
      ></base-pagination>
    </div>
  </div>
</template>

<script>
import Moment from "moment";
import { VBTooltip } from "bootstrap-vue/esm/directives/tooltip/tooltip";
import Spinner from "@/components/Spinner";
export default {
  directives: {
    BTooltip: VBTooltip
  },
  components: {
    Spinner
  },
  data: () => ({
    realtime_history: {
      page: 1
    },
    realtime_history_error: false
  }),
  computed: {
    page: {
      get() {
        return this.realtime_history.page;
      },
      set(val) {
        this.realtime_history.page = val;
      }
    },
    pages_count() {
      if (this.realtime_history) {
        return Math.ceil(this.realtime_history.count / 20);
      } else {
        return 1;
      }
    },
    not_found() {
      if (this.realtime_history_error) {
        if (this.realtime_history_error.status == 404) {
          return true;
        }
      }
      if (this.realtime_history) {
        return this.realtime_history.length == 0;
      }
      return false;
    },
    records_data() {
      if (this.realtime_history) {
        return this.realtime_history.results;
      } else {
        return null;
      }
    }
  },

  mounted() {
    let stored_cache = this.$store.getters.realtime_cache;
    this.getRealtimeHistory();
    if (stored_cache) this.realtime_history = stored_cache;
  },
  methods: {
    getClass(status) {
      return { ["badge-" + status.trim().toLowerCase()]: true };
    },
    getRealtimeHistory() {
      this.realtime_history_error = false;
      this.$axios
        .get(`search/realtime/history/?page=${this.realtime_history.page}`)
        .then(res => {
          for (let each of res.data.results) each.collapsed = 0;
          res.data.page = this.page;
          this.realtime_history = res.data;
          this.$store.commit("set_realtime_cache", res.data);
        })
        .catch(err => {
          this.realtime_history_error = err.response;
        });
    },
    changeState(index) {
      this.records_data[index].collapsed =
        1 - this.records_data[index].collapsed;
    },
    formatted_time(d) {
      return Moment(d).format("lll");
    },
    from_now(d) {
      return Moment(d).fromNow();
    },
    showSearchDetail(urn, country) {
      let url = `/search/detail/${urn}-${country}`;
      this.$router.push(url);
    },
    showHistoryDetail(h) {
      let url = "";
      if (h.request_type == "SINGLERECORD" && h.urn_list) {
        url = `/search/detail/${h.urn_list[0]}-${h.country_code}?live=true`;
      }
      this.$router.push(url);
      // this.$axios
      //   .get(`/search/realtime/status/?urid=${h.urid}`)
      //   .then(res => {
      //     var live_data_count = 0;
      //     this.$store.getters.live_data.forEach(i => {
      //     if (i.query == res.data.query) {
      //         live_data_count += 1;
      //       }
      //     });
      //     if (live_data_count != 0) {
      //       this.$store.getters.live_data.forEach((e, index) => {
      //         if (e.query == res.data.query) {
      //           this.$store.state.live_data.splice(index, 1);
      //           this.$store.state.live_data.splice(index, 0, res.data);
      //         }
      //       });
      //     } else {
      //       this.$store.commit("add_live_data", res.data);
      //     }
      //     this.$router.push(
      //       `/search/detail/${res.data.query}-${res.data.country_code}?live=true`
      //     );
      //   })
      //   .catch(err => {
      //     if (err.response) {
      //       this.show_detail_error = true;
      //     }
      //   });
    }
  },
  watch: {
    page: function() {
      this.getRealtimeHistory();
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }
};
</script>

<style scoped>
.history {
  border: 0.0625rem solid #e9ecef;
  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;
}
.history:hover {
  background-color: #e9ecef;
}
h3 {
  font-size: 22px;
  line-height: 1.3;
}
h4 {
  font-size: 17px;
  font-weight: 100;
}
h3:hover,
h4:hover,
.btn-result:hover,
.history-link:hover {
  text-decoration: underline;
  cursor: pointer;
}
.hide {
  opacity: 0;
}
.no-height {
  height: 0;
}
.subSection {
  transition: height 350ms ease-in-out, opacity 750ms ease-in-out;
}
</style>
